import React from 'react';
import { AppBar, Toolbar, Button, IconButton, TextField, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust for screens smaller than 600px
 const navigate = useNavigate();
    
    
      const handleNavigate = (path) => {
  
          navigate(path);
      }
    
  return (
    <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
      <Toolbar sx={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
        
        {/* Company Logo */}
        <Box
          component="img"
          src="../images/cosmicwebwork_trim.png" // Replace this with the actual image URL or import
          alt="Company Logo"
          sx={{
            width: isMobile ? '100%' : '350px',  // Full width on mobile, fixed on larger screens
            height: 'auto',
            objectFit: 'cover',
            objectPosition: 'center',
            marginBottom: isMobile ? '16px' : '0',  // Add spacing below logo on mobile
          }}
        />

        {/* Spacer to push search and buttons to the right */}
        <Box sx={{ flexGrow: 1 }}></Box>

        {/* Search Field and Button */}
        {!isMobile && (
          <>
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '4px',
                marginRight: '8px',
              }}
            />
            <IconButton color="inherit">
              <SearchIcon />
            </IconButton>
          </>
        )}

        {/* Sign Up and Sign In Buttons */}
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', width: isMobile ? '100%' : 'auto' }}>
          
             <Button
      color="inherit"
      onClick={() => handleNavigate('/login')}
      sx={{ marginLeft: '8px', width: isMobile ? '100%' : 'auto' }}
    >
      Sign Up/Login
    </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
