// src/components/SourceEngagement.js

import React from 'react';
import './SourceEngagement.css';

const SourceEngagement = () => {
  return (
    <div className="se-section" id="source-engagement">
      <div className="se-section-content">
        <h2 className="se-section-title">Source Engagement</h2>
        <p>
          Strategic process of building and maintaining relationships with suppliers, vendors, and
          other external partners. It involves active collaboration, communication, and trust-building
          to optimize business performance and achieve mutual goals.
        </p>
        <p>Our source network brings together every member of the manufacturing community and
           enables them to maximize their potential.</p>
        <p className="subsection-title">Key Components of Source Engagement:</p>
        
   
    
    <div class="location-grid">
    <div class="location-card">
      <h3>Supplier Relationship Management (SRM)</h3>
      <p> Building and managing relationships with suppliers to ensure a consistent supply of goods and services.</p>
    </div>
    <div class="location-card">
      <h3>Collaboration </h3>
      <p>Working closely with suppliers to identify opportunities for cost reduction, quality improvement, and innovation.</p>
    </div>
    <div class="location-card">
      <h3>Communication </h3>
      <p> Maintaining open and transparent communication channels to address issues and share information.</p>
    </div>
    <div class="location-card">
      <h3>Performance Management</h3>
      <p>Evaluating supplier performance against agreed-upon metrics and providing feedback.</p>
    </div>
    
     <div class="location-card">
      <h3>Risk Management</h3>
      <p>Identifying and mitigating potential risks associated with suppliers. </p>
    </div>
     <div class="location-card">
      <h3>Innovation </h3>
      <p>Collaborating with suppliers to develop new products, services, or processes.</p>
    </div>
     <div class="location-card">
      <h3>Sustainability</h3>
      <p>Integrating sustainability goals into supplier relationships.</p>
    </div>
  </div>
    
      </div>
    </div>
  );
};

export default SourceEngagement;
