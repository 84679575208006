// src/components/ManufacturingConsulting.js

import React from 'react';
import './ManufacturingConsulting.css'; // Import CSS file for styling

const ManufacturingConsulting = () => {
  return (
    <div className="manufacturing-consulting-section" id="manufacturing-consulting">
      <div className="manufacturing-consulting-section-content">
        <h2 className="manufacturing-consulting-section-title">Manufacturing Consulting</h2>
        <p>
          Global expertise in manufacturing, engineering, logistics, and supply chain management to help improve your own operations. Our senior leadership works with you to:
        </p>

      
       <div class="location-grid">
    <div class="location-card">
      <h3> Eliminate supply chain issues</h3>
    </div>
    <div class="location-card">
      <h3>Improve product quality</h3>
    </div>
    <div class="location-card">
      <h3>Remove waste and inefficiency</h3>
    </div>
    <div class="location-card">
      <h3>Reduce manufacturing costs</h3>
    </div>
         <div class="location-card">
      <h3>Shorten lead times</h3>
    </div>
    <div class="location-card">
      <h3>Improve working conditions</h3>
    </div>
    <div class="location-card">
      <h3>Implement Quality Assurance programs</h3>
    </div>
    <div class="location-card">
      <h3>Realize more significant economies of scale</h3>
    </div>
      <div class="location-card">
      <h3>Manage supply chain logistics</h3>
    </div>
    <div class="location-card">
      <h3>Open new facilities more quickly and efficiently</h3>
    </div>
  </div>
      
      </div>
    </div>
  );
};

export default ManufacturingConsulting;
