import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Button } from '@mui/material';
import { useLocation, useNavigate  } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import CommentPopup from '../components/CommentPopup';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const OrdersPage = () => {
  
     const { user } = useAuth();
    
    const { email, name, userId, roles, userTypes} =  user || {};
  const [orders, setOrders] = useState([]);
 const navigate = useNavigate(); 
    
    const fetchOrder = async () => { 
          if (email) {
      // Fetch the orders from API based on the user's email
      
         
        let orderURL = `${backendUrl}/api/users/${userId}/order/getAll`  
        if(Array.isArray(roles) && roles.some(userRole => userRole.role === 'Admin')){
            
            console.log("admin order url ")
           orderURL = `${backendUrl}/api/orders`
           }
        
      axios.get(orderURL)
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
        });
    }
    }
  useEffect(() => {
      
        fetchOrder();
  }, [email]);


  // Columns for Data Grid
  const orderColumns = [
    { field: 'orderId', headerName: 'Order ID', width: 150 },
    { field: 'name', headerName: 'Customer', width: 200 },
    { field: 'orderStatus', headerName: 'Status', width: 150 },
    { field: 'orderCreatedDate', headerName: 'Date', width: 150 },
        {
    field: 'actions',
    headerName: 'Actions',
    width: 250,
    renderCell: (params) => {
      if (Array.isArray(roles) && roles.some(userRole => userRole.role === 'Admin')) {
     
        return (
          <>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleApprove(params.row.orderId,'order')}
              style={{ marginRight: 8 }}
            >
              Update Order
            </Button>
       </>
      );
      }
      return null; // Return nothing if status is not "Provided"
    }
  },
     {
    field: 'view',
    headerName: 'View Comment',
    width: 150,
    renderCell: (params) => (
      <Button
        variant="text"
        color="primary"
        onClick={() => handleViewComments(params.row.orderId, 'order')}
      >
        View Details
      </Button>
    ),
  }
  ];
    
    
    
  
     const [selectedId, setSelectedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
      const [type, setType] = useState(null);
     const [openPopup, setOpenPopup] = useState(false);

  const handleViewComments = (id, type) => {
    setSelectedId(id);
    setOpenPopup(true);
       setType(type);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
       setType(null);
  };

  
   

  const handleApprove = (id, type) => {
    setSelectedId(id);
    setOpenDialog(true); // Open the confirmation dialog
      setType(type);
  };

    
  const handleConfirm = async (status, comment) => {
   
      
      if(type==='order'){
          
        let  updatStatusURL = `${backendUrl}/api/orders/${selectedId}`
    let  data = {
          id: selectedId,
         comments: [
      { statusId: status, comment: comment,  userId:userId   }
       ]
          
      }
          
         await axios.put(updatStatusURL, data)
      .then((response) => {
        console.log('Quote approved:', response.data);
        // Optionally refresh the grid or update the status locally
      })
      .catch((error) => {
        console.error('Error approving quote:', error);
      });
  }
      await fetchOrder();
      
      console.log("update quote  " + userId + "status "+ status);
   setType(null);
    setOpenDialog(false); // Close the dialog after confirming
  };



const handleDecline = (id) => {
  console.log('Declined row with id:', id);
  // Add your logic to handle the decline action (e.g., update the status, make API calls, etc.)
};


// Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
     const handleManufacturers = () => navigate('/manufacturers');
      const handleDashboard = () => navigate('/dashboard');
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
      <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
          userRoles={roles}
          userTypes={userTypes}
        />
    <div style={{ height: 400, width: '100%' }}>
      <h2>Orders List</h2>
      <DataGrid rows={orders} columns={orderColumns} pageSize={5}  getRowId={(row) => row.orderId}  />
    </div>
 {/* Confirmation Dialog */}
      <ConfirmationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={handleConfirm}
        type={type}
      />
               {openPopup && (
        <CommentPopup
          open={openPopup}
          handleClose={handleClosePopup}
          rowId={selectedId}
         type={type}
        />
      )}
      </Box>
      </Box>
  );
};

export default OrdersPage;
