import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchSupplierData = async () => {
  try {
    const response = await axios.get(`${backendUrl}/api/supplier`);
    return response.data;
  } catch (error) {
    console.error('Error fetching supplier data:', error);
    return [];
  }
};

export default fetchSupplierData;
