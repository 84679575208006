import React, { useState } from 'react';
import './Chatbot.css';
import {countryCodes} from '../common/countries';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Chatbot = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    inquiryType: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};

    const fullPhoneNumber = formData.countryCode + formData.phoneNumber;
    
    // Simple validation check for phone number length
    if (!formData.phoneNumber || fullPhoneNumber.length < 8) {
      newErrors.phoneNumber = 'Invalid Phone Number';
    }

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email || !isValidEmail(formData.email)) newErrors.email = 'Invalid Email ID';
    if (!formData.inquiryType) newErrors.inquiryType = 'Inquiry Type is required';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setServerError('');

    if (validateForm()) {
      const fullPhoneNumber = formData.countryCode + formData.phoneNumber; // Full phone number with country code
      
      const submissionData = {
        ...formData,
        phoneNumber: fullPhoneNumber,
      };
        
            // Construct form data object
          /*  const formData = {
                name: formData.name,
                email: formData.email,
                phoneNumber: fullPhoneNumber,
                category: formData.inquiryType,
                subject: formData.subject,
                message: formData.message
            };*/

        console.log(JSON.stringify(formData));
      // Proceed with the form submission
      fetch(`${backendUrl}/api/inquire`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      })
        .then(response => response.json())
        .then(data => {
          
          console.log(data);
             console.log(data.status);
          if (data.status) {
            setServerError(data.customMessage);
          } else {
            alert('Inquiry submitted successfully');
            setFormData({
              name: '',
              email: '',
              phoneNumber: '',
              countryCode: '',
              inquiryType: '',
              subject: '',
              message: ''
            });
            setIsFormOpen(false);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          alert('An error occurred while submitting the inquiry');
        });
    }
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  return (
    <div>
      <div id="chatbot" className="chatbot" onClick={toggleForm}>
        <img src="images/chat-bot.png" alt="Chatbot Icon" />
      </div>

      {isFormOpen && (
        <div className="form-popup">
          <h3>Inquiry Form</h3>
          {serverError && <div id="iserverError" className="error">{serverError}</div>}
          <form id="inquiryForm" onSubmit={handleSubmit}>
            <div id="inameError" className="error">{errors.name}</div>
            <input
              type="text"
              id="iname"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <div id="iemailError" className="error">{errors.email}</div>
            <input
              type="email"
              id="iemail"
              name="email"
              placeholder="Email Id"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <div id="iphoneNumberError" className="error">{errors.phoneNumber}</div>
            <div className="phoneNumberContainer">
              <select
                id="countryCode"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                required
              >
                <option value="">Select Country Code</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.code} ({country.country})
                  </option>
                ))}
              </select>

              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div id="inquiryTypeError" className="error">{errors.inquiryType}</div>
            <select
              id="inquiryType"
              name="inquiryType"
              value={formData.inquiryType}
              onChange={handleChange}
              required
            >
              <option value="">Select an option</option>
              <option value="General">General</option>
              <option value="Technical Support">Technical Support</option>
              <option value="Sales">Sales</option>
              <option value="Marketing">Marketing</option>
              <option value="Jobs">Jobs</option>
            </select>

            <div id="isubjectError" className="error">{errors.subject}</div>
            <input
              type="text"
              id="isubject"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />

            <div id="imessageError" className="error">{errors.message}</div>
            <textarea
              id="imessage"
              name="message"
              rows="4"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit" className="btn">Submit</button>
            <button type="button" className="btn cancel" onClick={toggleForm}>Close</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
