// src/components/QualityCertifications.js

import React from 'react';
import './QualityCertifications.css';

const QualityCertifications = () => {
  return (
    <div className="qc-section" id="quality-certifications">
      <div className="qc-section-content">
        <h2 className="qc-section-title">Quality Certifications and Expertise</h2>
        <p>
          They serve as benchmarks for product and service quality, customer satisfaction, and
          operational efficiency to <b>deliver 100% certified quality</b> for every project and customer.
        </p>
        <p>
          Our goods are always manufactured in line with regulatory production standards, customer
          specification, testing and compliance requirements, Customer components will be developed
          in the right facility to guarantee compliance.
        </p>
        <p>Our supplier facilities are<b> ISO 9001:2015 as mandatory criteria.</b></p>
        <p>We have quality expertise in <b>FMEA, APQP, PPAP, SPC, and MSA.</b></p>
        <p className="subsection-title">Key Areas of Quality Expertise</p>
      
         <div class="location-grid">
    <div class="location-card">
      <h3>Quality Management</h3>
      <p> Quality management ensures that the right systems and processes are in place to meet customer requirements. We encompasses a wide range of practices and policies aimed at ensuring consistent quality in products and services while fostering continuous improvement across the organization.</p>
    </div>
    <div class="location-card">
      <h3>Quality Assurance </h3>
      <p>Suppliers may be required to maintain their own Quality Assurance (QA) programs. The contract should outline the minimum standards for these programs, including documentation, quality control procedures, and the personnel responsible for overseeing quality.</p>
    </div>
    <div class="location-card">
      <h3>Communication </h3>
      <p>Regular meetings and status updates ensure that any issues, such as delays or bottlenecks, are addressed quickly and efficiently.</p>
    </div>
    <div class="location-card">
      <h3>Quality Control</h3>
      <p>Our Quality control (QC) plays a crucial role in manufacturing, ensuring that products meet predefined quality standards & Customer expectation with zero quality escapes which was achievable by qualified employees.</p>
    </div>
    
     <div class="location-card">
      <h3>Quality Improvement</h3>
      <p>Quality Improvement involves an ongoing commitment to improve processes, products, and services.Our goal is to increase efficiency, reduce waste, enhance customer satisfaction, and drive long-term business success.</p>
    </div>
     <div class="location-card">
      <h3>Statistical Process Control (SPC)</h3>
      <p>Statistical Process Control (SPC) or  Quality control tools is mandate by Quality Assurance agreement.</p>
    </div>
  </div>
       
      </div>
    </div>
  );
};

export default QualityCertifications;
