// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file for styling


const Footer = () => {
    
    
  return (
    <footer className="footer" id="footer">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col">
            <h4>CosmicWebWork </h4>
            <p>
              Ours is a global manufacturing network with interconnected functions, operations, and transactions to bring a product from prototype to final delivery with complex supply chain management for the on-demand manufacturing model.
            </p>
          </div>
          <div className="footer-col">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about-us#vision">Vision</Link></li>
              <li><Link to="/about-us#mission">Mission</Link></li>
              <li><Link to="/privacypolicy">Privacy and Policy</Link></li>
              <li><Link to="/terms">Terms and Services</Link></li>
              <li><Link to="/terms">Disclaimer</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Capability & Capacity</h4>
            <ul>
              <li><Link to="/capability-capacity#casting">Casting</Link></li>
              <li><Link to="/capability-capacity#forgings">Forgings</Link></li>
              <li><Link to="/capability-capacity#machining">Machining</Link></li>
              <li><Link to="/capability-capacity#fasteners">Fasteners</Link></li>
              <li><Link to="/capability-capacity#hydraulic">Hydraulic Components</Link></li>
              <li><Link to="/capability-capacity#fabrication">Fabrication & Press Components</Link></li>
              <li><Link to="/capability-capacity#painting">Painting</Link></li>
              <li><Link to="/capability-capacity#surface">Surface Coating/Plating</Link></li>
             <li><Link to="/capability-capacity#surface#raw">Supply of Ferrous and Non-Ferrous Metals</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Fields</h4>
            <ul>
              <li><Link to="/field-we-serve#construction">Construction & Infrastructure</Link></li>
              <li><Link to="/field-we-serve#industrial">Industrial Machinery & Equipment</Link></li>
              <li><Link to="/field-we-serve#medical">Medical Equipment Parts</Link></li>
              <li><Link to="/field-we-serve#transportation">Transportation</Link></li>
              <li><Link to="/field-we-serve#transportation">Energy Sectors - Renewable & Non-Renewable</Link></li>
              <li><Link to="/field-we-serve#food">Food Industry</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Our Strength</h4>
            <ul>
              <li><Link to="/quality-certifications">Quality Certifications</Link></li>
              <li><Link to="/maximum-transparency">Transparency</Link></li>
              <li><Link to="/manufacturing-locations">Manufacturing Locations</Link></li>
              <li><Link to="/manufacturing-consulting">Consulting</Link></li>
              <li><Link to="/supply-market-development">Supply Development</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-row">
          <div className="footer-col">
            <h4>Help and Contact</h4>
            <ul>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link to="/refund">Refund</Link></li>
              <li><Link to="/quote">Request a Quote</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Our Sales Team</h4>
            <dl>
              <dt>Sales</dt>
              <dd><a href="mailto:sales@cosmicwebwork.com">sales@cosmicwebwork.com</a></dd>
              <dt>Marketing</dt>
              <dd><a href="mailto:marketing@companyname.com">marketing@cosmicwebwork.com</a></dd>
            </dl>
          </div>
          <div className="footer-col">
            <h4>Our Support</h4>
            <dl>
              <dt>Support</dt>
              <dd><a href="mailto:support@cosmicwebwork.com">support@cosmicwebwork.com</a></dd>
              <dt>Directors</dt>
              <dd><a href="mailto:info@cosmicwebwork.com">info@cosmicwebwork.com</a></dd>
            </dl>
          </div>
          <div className="footer-col">
            <h4>Stay Connected</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61565406613294" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href="https://x.com/CosmicWebWork" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="https://www.instagram.com/cosmicwebwork" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com/company/cosmicwebwork/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
              <a href="https://www.youtube.com/@CosmicWebWork" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
            </div>
          </div>
        </div>
        <div className="footer-row">
       <p>&copy; 2024 CosmicWebWork</p>
          <p>Powered By IGermiTech Corporation</p>
         
        </div>
      </div>
    </footer>
  );
};

export default Footer;
