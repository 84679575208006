// src/components/AboutUs.js

import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-section" id="about-us">
      <div className="about-section-content">
        <h2 className="about-section-title">About Us</h2>
        <p>
          Ours is a global manufacturing network with interconnected functions, operations, and
          transactions to bring a product from prototype to final delivery with complex supply chain
          management for the on-demand manufacturing model.
        </p>
        <p>
          Benefits of having such a network reduce production cost & adaptability to Change for
          global situation.
        </p>
      
      
       <h2 id="mission" className="about-section-title">Mission</h2> 
<p>To seamlessly integrate interconnected functions, operations, and transactions, ensuring efficient and agile movement of products from prototype to final delivery. We leverage complex supply chain management to support an on-demand manufacturing model that meets the dynamic needs of our customers with precision and speed.</p>

 <h2 id="vision" className="about-section-title">Vision </h2>
<p>To be the leading innovator in on-demand manufacturing, recognized for our ability to deliver high-quality products swiftly and efficiently through a robust and interconnected supply chain. We aim to revolutionize the manufacturing industry by setting new standards for responsiveness, flexibility, and customer satisfaction.</p>
      </div>
    </div>
  );
};

export default AboutUs;
