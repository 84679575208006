import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchDealerData = async () => {
  try {
    const response = await axios.get(`${backendUrl}/api/dealer`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dealer data:', error);
    return [];
  }
};

export default fetchDealerData;
