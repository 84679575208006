import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Box, Button, TextField,  Container,Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SSOLogin = () => {
  const navigate = useNavigate();

     const { login } = useAuth();
      const [error, setError] = useState('');
    
  const handleLoginSuccess = (credentialResponse) => {
    const userObject = jwtDecode(credentialResponse.credential); 
        const email = userObject.email;
        const name = userObject.name;
        const givenName = userObject.given_name;
       const  familyName = userObject.family_name;
      const emailVerified = userObject.email_verified;
        console.log('User Info:', userObject);
     
        // Check if user exists in the system
    axios.get(`${backendUrl}/api/users?email=${email}`)
      .then((response) => {
        const user = response.data;
        if (response.data.userId) {
          // Redirect to dashboard if user exists
            const userId = response.data.userId;
            login(user); 
          navigate('/dashboard');
        } else {
               console.log('response.data.exists:', response.data.exists);
           const user = {
                  email:email,      // Replace with actual email
                  name:name,                  // Replace with actual name
                  givenName:givenName,                 // Replace with actual given name
                  familyName:familyName,
                  provider: 'GOOGLE',
                emailVerified:emailVerified
           };
      
               login(user); 
          // Redirect to UserDetails form if user doesn't exist
          navigate('/user-details');
        }
      })
      .catch((error) => {
        console.error('Error checking user:', error);
      });
  };

  const handleLoginFailure = (error) => {
    console.error('Login Failed:', error);
  };
    
      const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleEmailPasswordLogin = async (e) => {
    e.preventDefault();
    // Perform your email/password login logic here
    console.log('Email:', email );
    
      console.log('Email:', email );
               const newUser = {
              email: email,
              password: password
            };
      
     //  axios.post('/api/users/login', formData)
       axios.post(`${backendUrl}/api/users/login`, newUser)
      .then((response) => {
           const user = response.data;
           
           console.log(user.authorized);
      if (response.data.userId)  {
          // Redirect to dashboard if user exists
             console.log('Email:', response.data.userId);
            const userId = response.data.userId;
          if(response.data.authorized){
              login(user); 
           navigate('/dashboard');
             }else{
                 
                 if(response.data.provider){
                     console.log("you already registed using the SSO. Please login using SSO");
                 }else{
                     console.log("Please enter the correct password");
                 }
             }
                
        } else {
            
            console.log(" u provider "+ user.provider );
               login(user); 
          // If user doesn't exist, navigate to user details and pass email & password
          navigate('/user-details');
        }
      })
      .catch((error) => {
       // setError('Login failed. Please try again.');
        console.error("loginerror : "+error);
           
      });
      
  };

  return (
    <GoogleOAuthProvider clientId="336376527308-cdjptmihr8q0cr2dv4ofc773oel9eih8.apps.googleusercontent.com">
      <div>
   <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'center',
        padding: 3,
      }}
    >
      {/* Google Login Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Login with Google
        </Typography>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          text="signin_with"
          size="large"
          shape="rectangular"
          theme="outline"
        />
      </Box>

      {/* Divider Text */}
      <Typography variant="h6" sx={{ my: 2 }}>
        Or Login with Email/Password
      </Typography>

      {/* Email/Password Login Form */}
      <form onSubmit={handleEmailPasswordLogin} style={{ width: '100%', maxWidth: '400px' }}>
        {/* Email Input */}
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Password Input */}
        <TextField
          fullWidth
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginBottom: 4 }}
        >
          Login with Email/Password
        </Button>
      </form>
    </Container>
      </div>
    </GoogleOAuthProvider>
  );
};

export default SSOLogin;
