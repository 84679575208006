import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import { useAuth } from '../context/AuthContext';


const backendUrl = process.env.REACT_APP_BACKEND_URL;
const ConfirmationDialog = ({ open, handleClose, handleConfirm ,type }) => {
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [comment, setComment] = useState('');
    
  const { user } = useAuth();
  const { email, name, userId, roles, userTypes} =  user || {};

  useEffect(() => {
    // Fetch statuses from the service
      let statusURL = "";
      if(type === 'order'){
        statusURL = `${backendUrl}/api/order-status`;
       }else if(type === 'quote'){
          
          statusURL = `${backendUrl}/api/quote-status`;
          
      }else{
          
          console.log("No status available");
      }
      
    axios.get(statusURL)
      .then(response => setStatuses(response.data))
      .catch(error => console.error('Error fetching statuses:', error));
      
  }, [type]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
     handleConfirm(selectedStatus, comment)
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Status</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to update this record?
        </DialogContentText>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {statuses.filter(status => (Array.isArray(roles) && roles.some(userRole => userRole.role === 'Admin')) || status.statusName !== 'Supplier Responded')
            .map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.statusName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Comment"
          multiline
          rows={4}
          value={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
