import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Frequently Asked Questions
      </Typography>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is the primary focus of your company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our company specializes in global manufacturing with a focus on delivering high-quality products across various industries, including electronics, clothing, and more. We aim to provide comprehensive solutions that cater to our clients' needs from production to final delivery.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What industries do you serve?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We serve a wide range of industries, including construction and infrastructure, industrial machinery, medical equipment, food industry, transportation, and energy sectors. Our goal is to meet the specific needs of each industry by offering tailored solutions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How do you ensure the quality of your products?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We adhere to strict quality standards and certifications, such as ISO 9001:2015. Our quality control processes include thorough testing, compliance with regulatory standards, and continuous improvement practices to ensure that every product meets our high standards of quality.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is your approach to supply chain management?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our approach to supply chain management involves real-time visibility and efficient management of procurement, production, logistics, distribution, and returns. We focus on optimizing every aspect of the supply chain to ensure timely delivery and cost-effectiveness.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How can I get a quote for your services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To request a quote, please visit our Contact Us page or use the "Request a Quote" button available on our product and service pages. Provide details about your requirements, and our team will get back to you with a customized quote.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Where are your manufacturing facilities located?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our primary manufacturing facilities are located in India and China. We also have a global network of suppliers and partners to support our manufacturing capabilities and ensure efficient production and delivery.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
