import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import fetchSupplierData from '../services/fetchSupplierData';
import { useAuth } from '../context/AuthContext';
import { AppBar, Toolbar, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import LeftDrawer from '../components/LeftDrawer';
import { useNavigate  } from 'react-router-dom'; 

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate(); 
    
      const { user } = useAuth();
    
  const { email, name, familyName, givenName, userId, roles , userTypes } = user || {};  // Get the user's email passed from SSO login
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getSupplierData = async () => {
      const supplierData = await fetchSupplierData();
      setSuppliers(supplierData);
    };

    getSupplierData();
  }, []);

const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'companyName', headerName: 'Company Name', width: 150 },
    { field: 'supplierEmail', headerName: 'Email', width: 200 },
    { field: 'contactNumber', headerName: 'Contact Number', width: 150 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'addressLine', headerName: 'Address Line', width: 200 },
    {
  field: 'products',
  headerName: 'Products',
  width: 250,
  renderCell: (params) => {
    // Check if capabilities is an array and join if it is
    const products = params.value && Array.isArray(params.value) 
      ? params.value.map(cap => cap.product).join(', ') 
      : 'No products'; // Fallback if no capabilities

    return <span>{products}</span>; // Render the capabilities as a span
  },
},
    { field: 'additionalInfo', headerName: 'Additional Info', width: 250 },
  ];
    
 if (Array.isArray(user?.userRoles) && !user?.userRoles.some(userRole => userRole.role === 'Admin'))  {
    return <p>Access Denied. You must be an admin to view this content.</p>;
  }


// Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
    
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
     
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
    
     const handleManufacturers = () => navigate('/manufacturers');
    
      const handleDashboard = () => navigate('/dashboard');
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
      <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
          userRoles={roles}
          userTypes={userTypes}
        />
    <Box sx={{ width: '100%', height: 400 }}>
      <DataGrid
        rows={suppliers}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={isMobile ? 5 : 10}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-root': {
            overflowX: 'auto',
          },
          '@media (max-width: 600px)': {
            '& .MuiDataGrid-columnHeaders': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              fontSize: '12px',
            },
          },
        }}
      />
    </Box>
                    </Box>
                    </Box>
  );
};

export default Suppliers;
