import React, { useState, useEffect } from 'react';
import LeftDrawer from '../components/LeftDrawer';
import { AppBar, TextField, Button, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, Container, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate  } from 'react-router-dom'; 
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const QuotePage = () => {

     const navigate = useNavigate(); 
 
     const { user } = useAuth();
    
    const { email, name, roles, userTypes} =  user || {};
    
  const [orders, setOrders] = useState([]);
  const [quotes, setQuotes] = useState([]);
    
  const [formData, setFormData] = useState({
    name: name,
    email: email,
    message: '',
    attachment: null,
  });


      // Fetch company types and user types from the API when component mounts
  useEffect(() => {
          if (user) {
            setFormData({
                email,
                name
            });
        }
       }, [user, email, name]);
      
  const [errors, setErrors] = useState({
    nameError: '',
    emailError: '',
    messageError: '',
    serverError: '',
  });

  // Handle form submission
    
    
  const submitForm = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(document.getElementById("requestQuoteForm"));
    
    // Validate form fields
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    
    // Reset previous error messages
    const errorElements = document.getElementsByClassName('error');
    for (let i = 0; i < errorElements.length; i++) {
        errorElements[i].innerHTML = '';
    }

    if (!name) {
        document.getElementById('nameError').innerHTML = 'Name is required';
        return;
    }

    if (!email || !isValidEmail(email)) {
        document.getElementById('emailError').innerHTML = 'Invalid Email ID';
        return;
    }
    
    if (!message) {
        document.getElementById('messageError').innerHTML = 'Message is required';
        return;
    }
    
    console.log(formData);
    fetch(`${backendUrl}/api/quote-request`, {
        method: 'POST',
        body: formData
    })
    .then(response =>  {
        if (response.ok) {
            alert("Thank you for Requesting a Quote!! We will get back to you ASAP.");
            document.getElementById("requestQuoteForm").reset();
             setFormData({
                email,
                name
            });
            return;
        }
        return response.json();
        
    }).then(data => {
        if(data){
            if(typeof data.customMessage !== 'undefined'){
                document.getElementById('rserverError').innerHTML = data.customMessage;
            }
        }
    })
    .catch(error => {
        console.error('Error submitting the form:', error);
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
    
// Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
     const handleManufacturers = () => navigate('/manufacturers');
      const handleDashboard = () => navigate('/dashboard');
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
      <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
          userRoles={roles}
          userTypes={userTypes}
        />
      
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5', padding: '20px' }}>
      <Paper elevation={3} sx={{ padding: '30px', maxWidth: '600px', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Request a Quote
        </Typography>
        <form id="requestQuoteForm" onSubmit={submitForm} encType="multipart/form-data">
          {/* Server Error */}
          {errors.serverError && (
            <Typography variant="body2" color="error">
              {errors.serverError}
            </Typography>
          )}

          {/* Name Field */}
          <TextField
            label="Your Name"
            id="name"
            name="name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            error={!!errors.nameError}
            helperText={errors.nameError}
    InputProps={{
        readOnly: true,
    }}
          />

          {/* Email Field */}
          <TextField
            label="Your Email"
            id="email"
            name="email"
            type="email"
            fullWidth
            margin="normal"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            error={!!errors.emailError}
            helperText={errors.emailError}
    InputProps={{
        readOnly: true,
    }}
          />

          {/* Message Field */}
          <TextField
            label="Your Message"
            id="message"
            name="message"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            required
            error={!!errors.messageError}
            helperText={errors.messageError}
          />

          {/* Attachment Field */}
          <Typography variant="body2" gutterBottom>
            Attachment: (Allowed types: .pdf, .dwg, .step, .stl, .iges, .doc, .docx, .png, .jpeg, .ppt, .pptx. Up to 10 MB)
          </Typography>
          <Button variant="outlined" component="label" sx={{ marginBottom: '20px' }}>
            Upload Attachment
            <input
              type="file"
              id="attachment"
              name="attachment"
              hidden
              onChange={(e) => setFormData({ ...formData, attachment: e.target.files[0] })}
            />
          </Button>

          {/* Consent */}
          <Typography variant="body2" gutterBottom>
            In order to provide your quote, we need your consent to communicate with you and to store and process your personal data. You can unsubscribe or ask us to remove your data at any time.
          </Typography>

          {/* Submit Button */}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
</Box>
</Box>
  );
};

export default QuotePage;
