import React, { useState , useEffect} from 'react';
import {  AppBar, Toolbar, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { useNavigate  } from 'react-router-dom';  // To get user email from login
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import {countryCodes} from '../common/countries';

const productCategories = [
  'Automotive Parts',
  'Industrial Equipment',
  'Electrical Supplies',
  'Construction Materials',
  'Agricultural Tools',
  'Machinery',
  'Fasteners',
  'Hydraulic Components',
];

 const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DealerRegistrationForm = () => {
    
      const navigate = useNavigate(); 
    
         const { user } = useAuth();
    
    const { email, name, roles, userTypes} =  user || {};
    
    const [productCategories, setProductCategories] = useState([]); // To store fetched products

    
  const [formData, setFormData] = useState({
    companyName: '',
    dealerEmail: '',
    contactNumber: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    products: [],
    businessLicenseNumber: '',
    taxIdentificationNumber: '',
    additionalInfo: ''
  });



  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


     // Fetch products from the backend service
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/dealer-products`); // Replace with your actual API endpoint
        // Assuming response data is an array of DealerProduct objects
        const products = response.data.map((product) => product.product);
        setProductCategories(products);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Handle product selection change
  const handleProductChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      products: typeof value === 'string' ? value.split(',') : value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.companyName || !formData.dealerEmail || !formData.contactNumber || !formData.country || !formData.products.length) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/api/dealer/register`, formData);
      if (response.data) {
        alert('Dealer registered successfully!');
        setFormData({
          companyName: '',
          dealerEmail: '',
          contactNumber: '',
          country: '',
          state: '',
          city: '',
          postalCode: '',
          addressLine1: '',
          addressLine2: '',
          products: [],
          businessLicenseNumber: '',
          taxIdentificationNumber: '',
          additionalInfo: ''
        });
        setError('');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Registration failed. Please try again.');
    }
  };

    
 // Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
     const handleManufacturers = () => navigate('/manufacturers');
      const handleDashboard = () => navigate('/dashboard');
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
      <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
          userRoles={roles}
          userTypes={userTypes}
        />

    <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <h2>Dealer Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Company Name */}
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Dealer Email */}
        <TextField
          fullWidth
          label="Dealer Email"
          variant="outlined"
          name="dealerEmail"
          type="email"
          value={formData.dealerEmail}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Contact Number */}
        <TextField
          fullWidth
          label="Contact Number"
          variant="outlined"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Country */}
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Country</InputLabel>
          <Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          >
            {countryCodes.map((country) => (
              <MenuItem key={country.code} value={country.country}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* State */}
        <TextField
          fullWidth
          label="State"
          variant="outlined"
          name="state"
          value={formData.state}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/* City */}
        <TextField
          fullWidth
          label="City"
          variant="outlined"
          name="city"
          value={formData.city}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/* Postal Code */}
        <TextField
          fullWidth
          label="Postal Code"
          variant="outlined"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        {/* Address Line 1 */}
        <TextField
          fullWidth
          label="Address Line 1"
          variant="outlined"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Address Line 2 */}
        <TextField
          fullWidth
          label="Address Line 2"
          variant="outlined"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

     {/* Products (Multiple Select) */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Products</InputLabel>
        <Select
          name="products"
          multiple
          value={formData.products}
          onChange={handleProductChange}
          input={<OutlinedInput label="Products" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {productCategories.map((product) => (
            <MenuItem key={product} value={product}>
              <Checkbox checked={formData.products.indexOf(product) > -1} />
              <ListItemText primary={product} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

        {/* Business License Number */}
        <TextField
          fullWidth
          label="Business License Number"
          variant="outlined"
          name="businessLicenseNumber"
          value={formData.businessLicenseNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Tax Identification Number */}
        <TextField
          fullWidth
          label="Tax Identification Number"
          variant="outlined"
          name="taxIdentificationNumber"
          value={formData.taxIdentificationNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Additional Info */}
        <TextField
          fullWidth
          label="Additional Information"
          variant="outlined"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />

        <Button variant="contained" color="primary" type="submit">
          Register Dealer
        </Button>
      </form>
    </Box>
 </Box>
 </Box>
  );
};

export default DealerRegistrationForm;
