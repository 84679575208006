// src/components/CustomerTransparencyVisibility.js

import React from 'react';
import './CustomerTransparencyVisibility.css';

const CustomerTransparencyVisibility = () => {
  return (
    <div className="ctv-section" id="customer-transparency-visibility">
      <div className="ctv-section-content">
        <h2 className="ctv-section-title">Customer Transparency And Visibility</h2>
        <p>
          Transparency and visibility are essential for building trust, fostering collaboration, and driving performance. It involves openly sharing information, processes, and decision-making with stakeholders, both internal and external team.
        </p>
       <p>Manufacturers that partner with us are provided information during every step of the manufacturing process. We will provide you with complete reports, detailed analytics, project status and photos at every stage of production, offering visibility into the entire process.</p>
      
        <p>Our manufacturing operations include partnerships with many suppliers and facilities, spread across regions and capabilities. It offers suppliers visibility and the opportunity to win orders from clients, resulting in increase of orders and revenue.</p>
      
      </div> 
    </div>
  );
};

export default CustomerTransparencyVisibility;
