// LeftDrawer.js
import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';

const drawerWidth = 240;

const LeftDrawer = ({ handleDashboard, handleRequestQuote, handleQuoteList, handleOrders, handleTracker, handleDealer,handleDealers, handleSupplier,handleSuppliers, handleManufacturer, handleManufacturers, userRoles, userTypes }) => {

    
    
// Inside your component
useEffect(() => {
  console.log("userTypes:", userTypes);
    console.log("userRoles:", userRoles);
}, [userTypes,userRoles]); // This will log userTypes every time it changes
    
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: 'auto',  // Ensures it is below the header
      height: 'auto', // Allow it to fill the height of the page
          overflowY: 'auto', // Make the content scrollable
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
       <ListItem button onClick={handleDashboard}>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={handleRequestQuote}>
          <ListItemText primary="Request Quote" />
        </ListItem>
        <ListItem button onClick={handleQuoteList}>
          <ListItemText primary="Quote List" />
        </ListItem>
        <ListItem button onClick={handleOrders}>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button onClick={handleTracker}>
          <ListItemText primary="Tracker" />
        </ListItem>
       {/* Show Quote List only for Dealer role */}
{Array.isArray(userTypes) && userTypes.some(userType => userType.typeName === 'Dealer') && (
  <ListItem button onClick={handleDealer}>
    <ListItemText primary="Dealer" />
  </ListItem>
)}

{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'Admin') && (
  <ListItem button onClick={handleDealers}>
    <ListItemText primary="Dealers" />
  </ListItem>
)}
   {/* Show Quote List only for admin role */}
        { Array.isArray(userTypes) && (userTypes.some(userType => userType.typeName === 'Supplier')) && (
              <ListItem button onClick={handleSupplier}>
          <ListItemText primary="Supplier" />
        </ListItem>
              )}
{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'Admin') && (
  <ListItem button onClick={handleSuppliers}>
    <ListItemText primary="Suppliers" />
  </ListItem>
)}
      
       {/* Show Quote List only for admin role */}
        {Array.isArray(userTypes) && userTypes.some(userType => userType.typeName === 'Manufacturing') && (
           <ListItem button onClick={handleManufacturer}>
          <ListItemText primary="Manufacturer" />
        </ListItem>
        )}
{Array.isArray(userRoles) && userRoles.some(userRole => userRole.role === 'Admin') && (
  <ListItem button onClick={handleManufacturers}>
    <ListItemText primary="Manufacturers" />
  </ListItem>
)}
      </List>
    </Drawer>
  );
};

export default LeftDrawer;
