// src/components/CapabilityCapacity.js

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './CapabilityCapacity.css';

const CapabilityCapacity = () => {
  const scrollToEnroll = () => {
    // Implement the scroll function if needed
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };
    
    const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (

      <div class="fullpage">
    
  <div id="casting" class="section">
    <img src="images/casting.jpg" alt="Header 1" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
         <h2>Casting</h2>
          <p>Up to 1 Ton – Ferrous & Non-Ferrous Metal</p>
          <p>Cupola, Induction & Die Casting Process</p>
          <p>Standards: ASME/ASTM, DIN, ISO & IS</p>
          <p>Material Grade: SG iron, Grey Cast iron, Aluminum, Bronze & Stainless Steel</p>
          <button className="book-now-button" onclick="scrollToEnroll()">Request a Quote</button>
       
      </div>
      <div class="images-right">
        <img src="images/casting1.jpg" alt="Left Image 1"/>
        <img src="images/casting2.jpg" alt="Right Image 1"/>
      </div>
       </div>
    </div>
      
      <div id="forgings"  class="section">
       
    <img src="images/forgings.jpg" alt="Header 2" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
      <h2>Forgings</h2>
          <p>Up to 1 Ton – Alloy & Non-Alloy steels</p>
          <p>Open & Close die Forgings, Forged Shaft & Forged Square</p>
          <p>Standards: ASME/ASTM, DIN, ISO & IS</p>
             <button class="book-now-button"  onclick="scrollToEnroll()">Request a Quote</button> 
      </div>
      <div class="images-right">
        <img src="images/forging.jpg" alt="Left Image 2"/>
      </div>
    </div>
  </div>
      
      
      <div  id="machining" class="section">
    <img src="images/machined.jpg" alt="Header 1" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
           <h2>Machining</h2>
          <p>Small & Medium components below 1 Ton</p>
          <p>Customer drawing standards & tolerance limits can be achieved & measured</p>
             <button class="book-now-button"  onclick="scrollToEnroll()">Request a Quote</button>
       
      </div>
      <div class="images-right">
        <img src="images/machined1.jpg" alt="Left Image 1"/>
        <img src="images/machined.jpg" alt="Right Image 1"/>
      </div>
       </div>
    </div>
      
      <div  id="fasteners" class="section">
    <img src="images/fasterners1.jpg" alt="Header 2" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
       <h2>Fasteners</h2>
          <p>Catalogue & Custom designs</p>
          <p>Bolts, Screws, Nuts, Washers, Sockets, Pins, Circlips etc.</p>
          <p>Standards: ASME/ASTM, DIN, ISO & IS</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Request a Quote</button>
      </div>
      <div class="images-right">
        <img src="images/fasteners.jpg" alt="Left Image 2"/>
      </div>
    </div>
  </div>
      
         <div  id="hydraulic" class="section">
    <img src="images/Hydraulics.jpg" alt="Header 1" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
          <h2>Hydraulic Components</h2>
          <p>Catalogue & Custom designs</p>
          <p>Fittings, Pipes, Breathers, Heaters, RTD, Plugs, Caps, Hose, Flanges & Instrumentation</p>
          <p>Standards: ASME/ASTM, DIN, ISO & IS</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Order Now</button>
  
      </div>
      <div class="images-right">
        <img src="images/Hydraulics1.jpg" alt="Left Image 1"/>
      </div>
       </div>
    </div>
      
      <div id="fabrication" class="section">
    <img src="images/fabrication.jpg" alt="Header 2" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
        <h2>Fabrication & Press components</h2>
          <p>Below 1 Ton</p>
          <p>Structural & Precision Components manufactured with Qualified Welding Process</p>
          <p>Standards: ASME/ASTM, AWS, DIN & ISO</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Order Now</button>
      </div>
      <div class="images-right">
        <img src="images/fabrication1.jpg" alt="Left Image 2"/>
       <img src="images/fabrication2.jpg" alt="Right Image 1"/>
      </div>
    </div>
  </div>
      
      
           <div id="painting"  class="section">
    <img src="images/painting.jpg" alt="Header 1" class="section-header-image"/>
     
    <div class="section-content">
      <div class="content-left">
                    <h3>Painting</h3>
          <p>Small & Medium components below 1 Ton</p>
          <p>Fabricated & Machined Components processed with Qualified Painting Process</p>
          <p>Standards: ASME/ASTM, DIN, ISO & NACE</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Order Now</button>
  
      </div>
      <div class="images-right">
      </div>
       </div>
    </div>
      
      <div id="surface"  class="section">
    <img src="images/surface.jpg" alt="Header 2" class="section-header-image"/>
       <a href="https://www.freepik.com/free-photo/high-angle-worker-spraying-powder-paint-from-gun_33755331.htm#fromView=search&page=1&position=0&uuid=221140ca-0ffe-49a5-bd90-a429a7290678">Image by freepik</a>
    <div class="section-content">
      <div class="content-left">
        <h3>Surface Coating/Plating</h3>
          <p>Small & Medium components below 1 Ton</p>
          <p>Fabricated & Machined Components processed with Qualified Plating procedures</p>
          <p>Standards: ASME/ASTM, DIN, ISO & NACE</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Order Now</button>
      </div>
      <div class="images-right">
              <img src="images/coating.jpg" alt="Left Image 2"/>
      </div>
    </div>
  </div>
  
   <div id="raw" class="section">
   
      <h2> Supply of Ferrous and Non-Ferrous Metals</h2>
       <img src="images/steelraw.jpg" alt="Header 1" class="section-header-image"/>
    <div class="section-content">
      <div class="content-left">
    
       <h2> Supply of Ferrous and Non-Ferrous Metals</h2>
     <p>Tie up with Raw Material Stock list.</p>
<p>Plates, Forgings, Rods, Sheets, Coils, Strips, Angles, Flats, Channels, TMT Bars, Tubes & Pipes</p>
<p>Standards: ASME/ASTM, DIN, ISO & IS</p>
            <button class="book-now-button"  onclick="scrollToEnroll()">Order Now</button>
  
      </div>
      <div class="images-right">
              <img src="images/tmtrod.jpg" alt="Left Image 2"/>
      </div>
       </div>
    </div>
      
 
     
</div>
  );
};

export default CapabilityCapacity;
