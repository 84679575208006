import React, { useState, useEffect } from 'react';
import './Hero.css';
import { Box, Button, Typography, Modal } from '@mui/material';

 const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Hero() {
  const images = [
    '../images/hero_mc.jpeg',
    'images/transportation.jpg',
    'images/medical_1.jpg',
    'images/industry.jpg',
    'images/construction.jpg',
    'images/energy.jpg',
    'images/food.jpg'
  ];

  const headers = [
    'Welcome to Our Website',
    'Field We Serve - Transportation',
    'Field We Serve - Medical',
    'Field We Serve - Industry',
    'Field We Serve - Construction',
    'Field We Serve - Energy',
    'Field We Serve - Food '
  ];

  const contents = [
    'Ours is a global manufacturing network with interconnected functions, operations, and transactions to bring a product from prototype to final delivery with complex supply chain management for the on-demand manufacturing model.',
    '',
    '',
    '',
    '',
    '', /* <a href="https://www.freepik.com/free-photo/3d-solar-pannels-project-energy-saving_13328762.htm#fromView=search&page=1&position=0&uuid=eccef984-ac59-4f8a-8ca8-ca387e9ea9c2">Image by freepik</a>', */
    ''
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    
    

  useEffect(() => {
    const slideshowInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(slideshowInterval); // Cleanup interval on component unmount
  }, [images.length]);

  const submitForm = (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(document.getElementById("requestQuoteForm"));
    
    // Validate form fields
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    
    // Reset previous error messages
    const errorElements = document.getElementsByClassName('error');
    for (let i = 0; i < errorElements.length; i++) {
        errorElements[i].innerHTML = '';
    }

    if (!name) {
        document.getElementById('nameError').innerHTML = 'Name is required';
        return;
    }

    if (!email || !isValidEmail(email)) {
        document.getElementById('emailError').innerHTML = 'Invalid Email ID';
        return;
    }
    
    if (!message) {
        document.getElementById('messageError').innerHTML = 'Message is required';
        return;
    }
    
    fetch(`${backendUrl}/api/quote-request`, {
        method: 'POST',
        body: formData
    })
    .then(response =>  {
        if (response.ok) {
            alert("Thank you for Requesting a Quote!! We will get back to you ASAP.");
            document.getElementById("requestQuoteForm").reset();
            return;
        }
        return response.json();
        
    }).then(data => {
        if(data){
            if(typeof data.customMessage !== 'undefined'){
                document.getElementById('rserverError').innerHTML = data.customMessage;
            }
        }
    })
    .catch(error => {
        console.error('Error submitting the form:', error);
    });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="container">
      <div
        className={`hero ${!open ? 'hero-full-height' : ''}`} 
        style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      >
        <div className="hero-content">
          <h1 id="hero-content-header">{headers[currentImageIndex]}</h1>
          <p id="hero-content-p">{contents[currentImageIndex]}</p>
        </div>
{!open && (
       <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{ backgroundColor: '#941B0C' }} // Adjust the button color as needed
      >
        Request Quote
      </Button>
)}
{open && (
        <div className="quote-form" id="quote">

          <h2>Request a Quote</h2>
          <form id="requestQuoteForm" enctype="multipart/form-data">
            <div id="rserverError" className="error"></div>
            <div id="nameError" className="error"></div>
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" required />
            <div id="emailError" className="error"></div>
            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" name="email" required />
            <div id="messageError" className="error"></div>
            <label htmlFor="message">Your Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>
            
            <label htmlFor="attachment">Attachment:</label>
            <p>Allowed attachment types: .pdf, .dwg, .step, .stl, .iges, .doc, .docx, .png, .jpeg, .ppt, .pptx. up to 10 MB size</p>
            <input type="file" id="attachment" name="attachment" /><br /><br />
            <p>In order to provide your quote, we need your consent to communicate with you and to store and process your personal data. If you consent, please check the boxes below. You can unsubscribe or ask us to remove your data at any time.</p>
            <button type="button" onClick={submitForm}>Submit</button>
          </form>
        </div>
)}
      </div>
    </div>
  );
}

export default Hero;
