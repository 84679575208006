import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const fetchManufacturerData = async () => {
  try {
    const response = await axios.get(`${backendUrl}/api/manufacturing`);
    return response.data;
  } catch (error) {
    console.error('Error fetching manufacturer data:', error);
    return [];
  }
};

export default fetchManufacturerData;
