// src/components/FieldWeServe.js

import React, { useEffect }  from 'react';
import { useLocation } from 'react-router-dom';
import './FieldWeServe.css'; // Import CSS file for styling

const fields = [
  {
      id: 'construction',
    title: 'Construction & Infrastructure',
    description: 'Construction & Infrastructure encompasses the planning, design, construction, and maintenance of physical structures and facilities that support society\'s needs. It plays a vital role in economic development, urban planning, and quality of life.',
      
          list1: 'Manufacturing of Precise Construction & Infrastructure Components.',
      list2: 'Supply of Structural raw material, Consumables and Fasteners for various sectors.',
    image: 'images/construction.jpg'
  },
  {
       id: 'industrial',
    title: 'Industrial Machinery & Equipment',
    description: 'Manufacturing processes used to transform raw materials into finished products across a wide range of industries.',
          list1: 'Manufacturing of Precise Industrial Machinery & Equipment',
      list2: 'Supply of machinery, equipment, and other machinery parts.',
    image: 'images/industry.jpg'
  },
  {
        id: 'medical',
    title: 'Medical Equipment parts',
    description: 'Medical equipment parts are essential components that ensure the safe and effective functioning of medical devices. These parts must meet stringent quality standards to guarantee the accuracy and reliability of diagnostic and therapeutic procedures.',
    list1: 'Manufacturing of Precise Mechanical Components',
      list2: 'Supply of Gears, springs, bearings, and other moving parts.',
    image: 'images/medical_1.jpg'
  },
  {
        id: 'food',
    title: 'Food Industry',
    description: 'Food industry equipment plays a crucial role in the production, processing, packaging, and distribution of food products. This equipment must adhere to strict sanitation and safety standards to prevent contamination and ensure the quality of food.',
          list1: 'Structural Stainless-Steel Fabrication of Tanks, Pipelines & Trolleys',
      list2: 'Supplies of Stainless-steel Accessories & Equipment’s.',
    image: 'images/food.jpg'
  },
  {
        id: 'transportation',
    title: 'Transportation',
    description: 'Transportation plays a crucial role in connecting the world, facilitating the movement of goods and people across vast distances. The shipping, mining, and earthmoving sectors are particularly dependent on efficient and reliable transportation solutions. ',
                list1: 'Manufacturing of Casting & Precise Machined Components.',
      list2: 'Supply of Hydraulic parts & Accessories.',
    image: 'images/transportation.jpg'
  },
  {
        id: 'energy',
    title: 'Energy sectors - Renewable & Non-Renewable',
    description: 'Renewable energy sources are derived from natural processes that are constantly replenished. They are considered sustainable alternatives to traditional fossil fuels and offer several environmental benefits.',
             list1: 'Manufacturing of Casting & Precise Machined Components.',
      list2: 'Supply of structural fabrication parts with painting & Coating requirements.',
      image: 'images/energy.jpg'
  }
];

const FieldWeServe = () => {
    
         const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
    
  return (
    <div className="field-section" id="field-we-serve">
      <div className="field-section-content">
        <h2 className="field-section-title">Field We Serve</h2>
        <p>We serve industries including:</p>
        <div className="field-list">
          {fields.map((field, index) => (
            <div id={field.id} className="field-item" key={index}>
              <img src={field.image} alt={field.title} />
              <div className="field-content">
                <h3>{field.title}</h3>
                <p>{field.description}</p>
            <ul><li>{field.list1}</li>
               <li>{field.list2}</li>
           </ul>
          </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldWeServe;
