// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file for styling

const FooterShort = () => {
  return (
    <footer className="footer" id="footer" style={{ minHeight: '10vh' }}>
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col">
                 <p>&copy; 2024 CosmicWebWork</p>
          <p>Powered By IGermiTech Corporation</p>
            
          </div>
          <div className="footer-col">
            <h4>Stay Connected</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=61565406613294" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
              <a href="https://x.com/CosmicWebWork" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="https://www.instagram.com/cosmicwebwork" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com/company/cosmicwebwork/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
              <a href="https://www.youtube.com/@CosmicWebWork" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
            </div>
          </div>
      </div>
      </div>
    </footer>
  );
};

export default FooterShort;
