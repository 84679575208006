// src/components/OnTimeDelivery.js

import React from 'react';
import './OnTimeDelivery.css';

const OnTimeDelivery = () => {
  return (
    <div className="ot-section" id="ontime-delivery">
      <div className="ot-section-content">
        <h2 className="ot-section-title">On Time Delivery</h2>
        <p>
          Accelerated delivery times can significantly impact a company’s competitiveness, we are
          ensuring parts arrive ahead of schedule, we are not only meeting customer expectations but
          also potentially gaining a competitive edge in the market.
        </p>
        <p className="subsection-title">Strategies followed to expedite part delivery</p>
     
    
          <div class="location-grid">
    <div class="location-card">
      <h3>Efficient Supply Chain Management</h3>
      <p> Effective Supply Chain Management and market development can lead to cost reductions through better pricing, improved efficiency, and reduced procurement costs.</p>
    </div>
    <div class="location-card">
      <h3>Lean Manufacturing</h3>
      <p>Lean manufacturing, help identify inefficiencies and errors in the production process, ensuring that precision is maintained over time.</p>
    </div>
    <div class="location-card">
      <h3>Advanced Forecasting </h3>
      <p>Using data analytics and forecasting tools enables us to anticipate demand changes and adjust production plans accordingly. Predictive models can help in maintaining the right level of inventory and production capacity.</p>
    </div>
    <div class="location-card">
      <h3>Just-in-Time (JIT) Production</h3>
      <p>This approach emphasizes the synchronization of production processes with customer demand and supply chain activities, ensuring that materials and products arrive just in time for use in production or sale.</p>
    </div>
    
     <div class="location-card">
      <h3>Collaboration with Logistics Providers</h3>
      <p>Collaboration with logistics providers is an essential aspect of modern supply chain management. Effective collaboration ensures that logistics services align with our goals for efficiency, cost control, and customer satisfaction. </p>
    </div>

  </div>
       
    
      </div>
    </div>
  );
};

export default OnTimeDelivery;
