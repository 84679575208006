// src/components/ExpertLogisticsManagement.js

import React from 'react';
import './ExpertLogisticsManagement.css';

const ExpertLogisticsManagement = () => {
  return (
    <div className="el-section" id="expert-logistics-management">
      <div className="el-section-content">
        <h2 className="el-section-title">Expert Logistics Management</h2>
        <p>
          Our expert team ensures your products reach their destination on time and at the lowest
          possible cost. Leveraging our deep-rooted partnerships within the global supply chain, we
          optimize shipping routes, streamline customs clearance, and provide end-to-end visibility,
          minimizing delays and expenses.
        </p>
        <p>
          Logistics is the strategic management of the flow of people, goods, and information from
          point of origin to point of consumption to meet requirements of customers or corporations.
          It involves the integration of information and resource planning, inventory management,
          transportation, warehousing, packaging, and distribution.
        </p>
        <p  className="subsection-title" >Key Components of Logistics</p>
   
      
            
  <div class="location-grid">
    <div class="location-card">
      <h3>Transportation</h3>
      <p>Moving goods from one location to another, including modes like road, rail, air, sea, and pipeline.</p>
    </div>
    <div class="location-card">
      <h3>Warehouse</h3>
      <p>Storing goods until they are needed for distribution.</p>
    </div>
    <div class="location-card">
      <h3>Inventory management</h3>
      <p> Controlling the quantity and location of inventory to meet customer demand.</p>
    </div>
    <div class="location-card">
      <h3>Order processing </h3>
      <p>Managing customer orders from receipt to fulfillment.</p>
    </div>
          <div class="location-card">
      <h3>Packaging and labeling</h3>
      <p> Protecting products during transportation and providing necessary information.</p>
    </div>
    <div class="location-card">
      <h3>Supply and demand planning</h3>
      <p> Forecasting customer needs and aligning supply chain operations accordingly.</p>
    </div>
    <div class="location-card">
      <h3>Returns management</h3>
      <p>Handling returned goods and processing refunds or exchanges.</p>
    </div>
  </div>
      
      </div>
    </div>
  );
};

export default ExpertLogisticsManagement;
