// src/components/SupplyChainManagement.js

import React from 'react';
import './SupplyChainManagement.css';

const SupplyChainManagement = () => {
  return (
    <div className="sc-section" id="supply-chain-management">
      <div className="sc-section-content">
        <h2 className="sc-section-title">Supply Chain Management</h2>
        <p>
          Real-time visibility plays a crucial role in supply chain management (SCM). It provides
          immediate access to accurate data about goods in transit, including details about
          consignment status, location, and condition within the supply chain.
        </p>
        <p>
          Supply chain management (SCM) play Key role in procurement, production, logistics,
          distribution & return handling:
        </p>
    
          
  <div class="location-grid">
    <div class="location-card">
      <h3>Procurement</h3>
      <p>Sourcing raw materials and components.</p>
    </div>
    <div class="location-card">
      <h3>Production</h3>
      <p>Manufacturing or assembling the product.</p>
    </div>
    <div class="location-card">
      <h3>Logistics</h3>
      <p>Transportation, warehousing, and inventory management.</p>
    </div>
    <div class="location-card">
      <h3>Distribution </h3>
      <p>Delivering the product to customers.</p>
    </div>
        <div class="location-card">
      <h3>Returns </h3>
      <p>Handling returned products and processing refunds or exchanges.</p>
    </div>
  </div>
    
      </div>
    </div>
  );
};

export default SupplyChainManagement;
