// src/components/QualityControl.js

import React from 'react';
import './QualityControl.css';

const QualityControl = () => {
  return (
    <div className="qc-section" id="quality-control">
      <div className="qc-section-content">
        <h2 className="qc-section-title">Quality Control</h2>
        <p>
          Quality control (QC) plays a crucial role in manufacturing, ensuring that products meet
          predefined quality standards & Customer expectation with zero quality escapes which was
          achievable by qualified employees.
        </p>
        <p>
          Improving supplier quality control is essential for maintaining consistent product quality by
          following below methodology.
        </p>
    <div class="location-grid">
    <div class="location-card">
      <h3>Supplier Selection Criteria</h3>
      <p>Our Supplier Selection Criteria includes evaluating suppliers' environmental practices, ethical standards, and social responsibility.</p>
    </div>
    <div class="location-card">
      <h3>Supplier Agreements and Contracts </h3>
      <p>Supplier agreements and contracts are formalized arrangements that outline the terms of engagement between a company and its suppliers. One of the key components of these agreements is quality control, which ensures that the goods and services provided by suppliers meet the required standards. Effective quality control in supplier agreements safeguards the integrity of the supply chain, minimizes defects, and enhances customer satisfaction.</p>
    </div>
    <div class="location-card">
      <h3>Supplier Performance Metrics </h3>
      <p>Our performance metrics and regular reviews to monitor supplier performance. Provide feedback and support to help suppliers meet quality and delivery expectations.</p>
    </div>
    <div class="location-card">
      <h3>Collaborative Communication</h3>
      <p> Our Open, transparent communication is vital for successful collaboration. Sharing real-time data, forecasts, and expectations helps both parties to anticipate changes and respond proactively.Frequent communication fosters closer collaboration with suppliers, often resulting in stronger, more reliable supply chains.</p>
    </div>
    
     <div class="location-card">
      <h3>Risk Management</h3>
      <p>Our contingency Planning for potential supply chain disruptions, including alternative suppliers, inventory management strategies, and crisis response protocols.
and regularly assess risks related to suppliers and the supply market, including geopolitical, economic, and environmental factors. </p>
    </div>
     <div class="location-card">
      <h3>Continuous Improvement</h3>
      <p>JIT promotes ongoing efforts to improve processes, reduce inefficiencies, and enhance flexibility.</p>
    </div>
  </div>
       
    
      </div>
    </div>
  );
};

export default QualityControl;
