// src/components/ManufacturingLocations.js


import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './ManufacturingLocations.css'; // Import CSS file for styling



const ManufacturingLocations = () => {
    
    
    const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
    
  return (
    <div className="ml-section" id="manufacturing-locations">
      <div className="ml-section-content">
        <h2 className="ml-section-title">Manufacturing Locations</h2>
        <p>
          Manufacturing location is a critical strategic decision that significantly impacts a
          company's supply chain, costs, and market access. The optimal location depends on various
          factors. We primarily manufacture in India & China,</p>
      

<h3  className="subsection-title" id="globalmanufacturing" >Global Manufacturing Network</h3>     
      <p>A global manufacturing network involves setting up production facilities across different countries to take advantage of regional strengths, such as cost efficiencies, proximity to key markets, and reduced operational risks. By strategically locating factories in regions with lower labor costs, favorable tax regimes, or abundant raw materials, companies can significantly reduce production costs while maintaining high output.</p>

<p>Additionally, operating in multiple countries diversifies the supply chain, mitigating risks associated with political instability, trade barriers, or natural disasters in any single region. This approach also enables manufacturers to respond more quickly to changes in demand across different markets, improving customer responsiveness and reducing lead times.</p>

<p>Accessing new markets through local production facilities also allows companies to better understand regional consumer preferences and regulations, enabling them to tailor products to meet specific market needs. This presence often enhances brand visibility and fosters stronger relationships with local partners and governments.</p>
      
  <div class="location-grid">
    <div class="location-card">
      <h3>Reduce Production Costs</h3>
      <p>By situating facilities in regions with lower labor and operational cost, companies can significantly cut down on expenses.</p>
    </div>
    <div class="location-card">
      <h3>Enhance Market Access</h3>
      <p>Establishing a presense in mutipe countries enables companies to better server ocal markets, reducing shipping times and costs, and improvign customer satisfaction..</p>
    </div>
    <div class="location-card">
      <h3>Diversify Risk</h3>
      <p> Spreading production across different locations helps mitigate risks associated with geopolitical instability, natural disasters, and supply chain disruptions.</p>
    </div>
    <div class="location-card">
      <h3>Adapt to Market Changes </h3>
      <p>A global network allows for greater flexibility in responding to shifts in demand and maket conditions, ensuring that production can be scaled up aor down as needed.</p>
    </div>
  </div>

      
<h3  className="subsection-title" id="regionalhubs" >Regional Hubs</h3>     
      <p>Creating manufacturing centers in specific regions to serve markets.</p>

<p>Regional hubs are centralized manufacturing or distribution centers located in key geographic areas to serve the surrounding regions more efficiently. These hubs play a crucial role in optimizing global supply chains by consolidating production, warehousing, and distribution activities in strategic locations. They enable us to minimize transportation costs, reduce lead times, and respond swiftly to market demands.</p>

<p>These hubs often act as gateways for expanding into adjacent markets, creating a strong foundation for global competitiveness. They are instrumental in ensuring that products reach markets faster and at a lower cost while adapting to localized needs.</p>
      
  <div class="location-grid">
    <div class="location-card">
      <h3>Proximity to Markets</h3>
      <p>By situating hubs closer to key markets, companies can reduce shipping times and costs, ensuring faster delivery and improved customer satisfaction.</p>
    </div>
    <div class="location-card">
      <h3>Cost Efficiency</h3>
      <p>Regional hubs can take advantage of local resources, labor, and infrastructure, potentially lowering production and operational costs.</p>
    </div>
    <div class="location-card">
      <h3>Scalability</h3>
      <p>These hubs allow companies to quickly scale operations up or down in response to reginonal demand fluctuations.</p>
    </div>
    <div class="location-card">
      <h3>Customization</h3>
      <p>Manufacturing centers in specific regions can tailor products to meet local preferences and regulatory requirements, enhancing market relevance.</p>
    </div>
  </div>

      
      
<h3  className="subsection-title" id="single-country-focus" >Single-Country Focus</h3>     
      <p> Concentrating manufacturing in one country for various
              reasons, such as expertise or market dominance.</p>

<p>Additionally, a single-country focus can foster stronger relationships with local suppliers, partners, and government entities, allowing for smoother operations and often quicker compliance with regulations. It also reduces the complexities associated with cross-border logistics, such as fluctuating exchange rates, customs duties, and shipping delays.</p>

      
  <div class="location-grid">
    <div class="location-card">
      <h3>Expertise and Specialization</h3>
      <p>A highly skilled workforce, advanced technology, or specialized knowledge in a particular industry, leading to higher quality and efficiency in production.</p>
    </div>
    <div class="location-card">
      <h3>Market Dominance</h3>
      <p>Focusing on a single country can help a company dominate the local market, building strong brand recognition and customer loyalty.</p>
    </div>
    <div class="location-card">
      <h3>Cost Advantages</h3>
      <p>The chosen country might offer lower labor costs, favorable tax policies, or other economic incentives that reduce overall production expenses.</p>
    </div>
    <div class="location-card">
      <h3>Streamlined Operations </h3>
      <p>Centralizing manufacturing in one location simplifies logistics, supply chain management, and coordination, leading to more efficient operations.</p>
    </div>
      
       <div class="location-card">
      <h3>Regulatory Environment </h3>
      <p>A stable and favorable regulatory environment can provide a predictable and supportive framework for business operations.</p>
    </div>
  </div>


      
      </div>
    </div>
  );
};

export default ManufacturingLocations;
