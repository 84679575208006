import React, { useState, useEffect } from 'react';
import {AppBar, Toolbar, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Box, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { useNavigate  } from 'react-router-dom';  // To get user email from login
import axios from 'axios';  // For API calls
import LeftDrawer from '../components/LeftDrawer';
import { useAuth } from '../context/AuthContext';
import {countryCodes} from '../common/countries';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SupplierRegistrationForm = () => {
    
    
      const navigate = useNavigate(); 
    
         const { user } = useAuth(); 
    
    const { email, name, roles, userTypes} =  user || {};
    
     const [supplierProducts, setSupplierProducts] = useState([]); // To store fetched products
 
  const [formData, setFormData] = useState({
    companyName: '',
    supplierEmail: '',
    contactNumber: '',
    country: '',
    addressLine: '',
    products: [],
    additionalInfo: ''
  });

   // Fetch products from the backend service
useEffect(() => {
    const fetchSupplierProducts = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/supplier-products`); // Replace with your actual API endpoint
        const products = response.data.map((product) => product.product);
        setSupplierProducts(products);
      } catch (error) {
        console.error('Error fetching supplier products:', error);
      }
    };

    fetchSupplierProducts();
  }, []);

  // Handle product selection change
  const handleProductChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      products: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.companyName || !formData.supplierEmail || !formData.contactNumber || !formData.country || !formData.products.length) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/api/supplier/register`, formData);
      if (response.data) {
        alert('Supplier registered successfully!');
        setFormData({
          companyName: '',
          supplierEmail: '',
          contactNumber: '',
          country: '',
          addressLine: '',
          products: [],
          additionalInfo: ''
        });
        setError('');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError('Registration failed. Please try again.');
    }
  };

    
// Navigation handlers for the menu items
  const handleRequestQuote = () => navigate('/request-quote');
  const handleQuoteList = () => navigate('/quote-list');
  const handleOrders = () => navigate('/orders');
     const handleTracker = () => navigate('/tracker');
      const handleDealer = () => navigate('/dealerreg');
      const handleDealers = () => navigate('/dealers');
  const handleSupplier = () => navigate('/suppliereg');
      const handleSuppliers = () => navigate('/suppliers');
     const handleManufacturer = () => navigate('/manufacturerreg');
     const handleManufacturers = () => navigate('/manufacturers');
      const handleDashboard = () => navigate('/dashboard');
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header at the Top */}
      <AppBar position="static" sx={{ backgroundColor: '#941B0C' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

     <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <LeftDrawer
        handleDashboard={handleDashboard}
          handleRequestQuote={handleRequestQuote}
          handleQuoteList={handleQuoteList}
          handleOrders={handleOrders}
          handleTracker={handleTracker}
          handleDealer={handleDealer}
          handleDealers={handleDealers}
          handleSupplier={handleSupplier}
       handleSuppliers={handleSuppliers}
          handleManufacturer={handleManufacturer}
      handleManufacturers={handleManufacturers}
          userRoles={roles}
          userTypes={userTypes}
        />

    <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
      <h2>Supplier Registration</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Company Name */}
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Supplier Email */}
        <TextField
          fullWidth
          label="Supplier Email"
          variant="outlined"
          name="supplierEmail"
          type="email"
          value={formData.supplierEmail}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Contact Number */}
        <TextField
          fullWidth
          label="Contact Number"
          variant="outlined"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Country */}
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Country</InputLabel>
          <Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          >
            {countryCodes.map((country) => (
              <MenuItem key={country.code} value={country.country}>
                {country.country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Address Line */}
        <TextField
          fullWidth
          label="Address Line"
          variant="outlined"
          name="addressLine"
          value={formData.addressLine}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
          required
        />

        {/* Products (Multiple Select) */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Products</InputLabel>
        <Select
          name="products"
          multiple
          value={formData.products}
          onChange={handleProductChange}
          input={<OutlinedInput label="Products" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {supplierProducts.map((product) => (
            <MenuItem key={product} value={product}>
              <Checkbox checked={formData.products.indexOf(product) > -1} />
              <ListItemText primary={product} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

        {/* Additional Info */}
        <TextField
          fullWidth
          label="Additional Information"
          variant="outlined"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ marginBottom: 2 }}
        />

        <Button variant="contained" color="primary" type="submit">
          Register Supplier
        </Button>
      </form>
    </Box>
 </Box>
 </Box>
  );
};

export default SupplierRegistrationForm;
