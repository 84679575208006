// src/components/SupplyMarketDevelopment.js

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SupplyMarketDevelopment.css'; // Import CSS file for styling

const SupplyMarketDevelopment = () => {
    
       const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
    
  return (
    <div className="supply-section" id="supply-market-development">
      <div className="supply-section-content">
        <h2 className="supply-section-title">Supply Market Development</h2>
      <p>Our strategies and actions taken to enhance and expand the market for acquiring goods and services. It focuses on building and optimizing the supply base, exploring new sources of supply, and improving relationships with suppliers to ensure a reliable, cost-effective, and high-quality supply chain.</p>
      <h3 id="customer"  className="subsection-title">Based on customer requirements change</h3>
        <p>we’ll work to assess and recommend the best-fit solution, which could include:</p>
       
       <div class="supple-grid">
    <div class="supple-card">
      <h3>A supplier changes</h3>
      <p>Supplier changes, while sometimes necessary, can introduce risks such as disruptions to the supply chain, quality control issues, or delays in product delivery during the transition period. We carefully vet new suppliers to ensure that they can meet the required standards for materials, production timelines, and compliance with industry regulations.</p>
    </div>
    <div class="supple-card">
      <h3>Development and implementation of a new production line</h3>
      <p>The successful development and implementation of a new production line can significantly boost a company's competitive advantage by expanding capacity, reducing costs, and delivering higher-quality products to the market faster.</p>
    </div>
    <div class="supple-card">
      <h3>Implementation of a new factory</h3>
      <p>Implementing a new factory is a complex, multi-phase project that requires careful planning, coordination, and execution to ensure operational success. Our process begins with a comprehensive feasibility study, which evaluates factors such as location, proximity to supply chains, labor availability, infrastructure, and market demand.</p>
    </div>
   
  </div>
      
      
        <h3 id="scope" className="subsection-title">Scope your requirements</h3>
      <p>Scoping requirements is a crucial step in any project, as it defines the objectives, deliverables, resources, and timelines needed to achieve success. Our process begins with gathering and documenting detailed information about what the project needs to accomplish. It includes understanding the stakeholders’ expectations, identifying project constraints (such as budget and time), and specifying technical and functional requirements.</p>
      <div class="location-grid">
    <div class="location-card">
      <h3>Leveraging our RFQ process</h3>
     </div>
    <div class="location-card">
      <h3>Gather multiple quotes for your RFQ</h3>
     </div>
    <div class="location-card">
      <h3>Perform a “Should Cost” analysis</h3>
    </div>
    <div class="location-card">
      <h3>Seek optimization within the selected solution</h3>
    </div>
  </div>
        
        <h3 id="bestfit"  className="subsection-title">Best fit for your production</h3>
      <p>Choosing the best fit for your production involves evaluating various factors to ensure that your manufacturing processes, equipment, and resources are optimized for efficiency, quality, and cost-effectiveness. Our assessment begins with an analysis of your production requirements, such as the type and volume of products being manufactured, the materials used, and the desired production speed.</p>
         <div class="supple-grid">
    <div class="supple-card">
      <h3>Production precision</h3>
      <p> High levels of precision are critical for ensuring product quality, performance, and reliability, especially in industries such as aerospace, automotive, medical devices, and electronics, where even minor deviations can lead to significant issues.</p>
    </div>
    <div class="supple-card">
      <h3>Annual quantities and projected production growth</h3>
      <p>Accurately forecasting annual quantities and growth enables us to optimize operations, meet market demand, and strategically plan for the future.</p>
    </div>
    <div class="supple-card">
      <h3>Stability of the ongoing product demand</h3>
      <p>Stability in ongoing product demand is critical for maintaining efficient operations and financial health. By understanding and proactively managing the factors that influence demand, we can ensure a more predictable flow of orders, optimize production, and build stronger, more resilient business strategies.</p>
    </div>
   
  </div>
      
      
      
      </div>
    </div>
  );
};

export default SupplyMarketDevelopment;
